import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const SessionRefresher = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;
    // Refresh token interval (e.g., every 10 minutes)
    const refreshInterval = setInterval(async () => {
      try {
        await getAccessTokenSilently({cacheMode: "off"});
        console.log("Session refreshed successfully.");
      } catch (error) {
        console.error("Session refresh failed:", error);
      }
    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    return () => clearInterval(refreshInterval);
  }, [isAuthenticated, getAccessTokenSilently]);

  return null; // This component doesn't render anything
};

export default SessionRefresher;